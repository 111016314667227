import { HelloKlarityProviderDraftStorageKey, DRAFT_STORAGE_VERSION } from 'constants/common';
import { IDraftStorage, ISpecialtyNServiceTypeResponse, SPECIALTY_CONDITION_KEYS } from 'types/helloKlarity';

export const getDraftDataPrefixKey = (providerId: number) => `${HelloKlarityProviderDraftStorageKey}_${providerId}`;

// 用于管理Booking link draft data的版本。方便以后删除
// 不要轻易修改这个function返回的key值！！！，因为会导致之前保存的草稿被删除
// 删除的的逻辑是，只保留当前version的storage，其他 ‘HelloKlarityProviderDraftStorageKey’前缀的storage都会被删除
export const getTheDraftDataKey = (providerId: number) => {
    return `${getDraftDataPrefixKey(providerId)}${DRAFT_STORAGE_VERSION}`;
};

export const checkLocalStorageCapacity = () => {
    const dataSize = Object.keys(localStorage).reduce((acc, key) => acc + localStorage[key].length, 0);
    const remainingSpace = 5 * 1024 * 1024 - dataSize; // 5MB - 已使用的空间
    return remainingSpace > 0;
};
// 将数据存储到 localStorage，包括当前时间戳
export const setDraftData = (value: IDraftStorage, providerId: number) => {
    if (checkLocalStorageCapacity()) {
        const now = new Date();
        const item = {
            ...value,
            expiry: now.getTime() + 3 * 24 * 60 * 60 * 1000, // 3 天后的时间戳
        };
        localStorage.setItem(getTheDraftDataKey(providerId), JSON.stringify(item));

        return true;
    } else {
        return false;
    }
};

// 从 localStorage 获取数据
export const getDraftData = (providerId: number) => {
    const storageKey = getTheDraftDataKey(providerId);
    const itemStr = localStorage.getItem(storageKey);
    // 如果数据不存在，则返回 null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // 检查数据是否过期
    if (now.getTime() > item.expiry) {
        // 如果数据过期，则从 localStorage 中移除并返回 null
        localStorage.removeItem(storageKey);
        return null;
    }
    return item;
};

export const removeDraftStorage = (providerId: number) => {
    localStorage.removeItem(getTheDraftDataKey(providerId));
};

export const prereleaseOriginReplacement = (url: string, newOrigin?: string) => {
    let newUrl = url;
    // 如果要恢复到原本的origin，只需要让第二个参数为空即可
    if (url && newOrigin) {
        try {
            const urlObj = new URL(url);

            const { origin } = urlObj;

            newUrl = url.replace(origin, newOrigin);
        } catch (e) {
            console.error(e);
        }
    }

    return newUrl;
};

export const getInfoFromLinkName = (linkName: string, specialtyNServiceTypeData?: ISpecialtyNServiceTypeResponse):{
    state: string,
    specialty: string,
    serviceType: string,
    bookingLinkName: string,
} => {
    const info = {
        state: '',
        specialty: '',
        serviceType: '',
        bookingLinkName: linkName,
        conditions: [],
    };

    if (specialtyNServiceTypeData) {
        const specialty = specialtyNServiceTypeData?.serviceSpecialtyList.find((e) => {
            const pattern = new RegExp(`\\b${e}\\b`, 'i');
            return pattern.test(linkName);
        }) || '';
        const serviceType = specialtyNServiceTypeData?.serviceTypeList.find((e) => {
            const pattern = new RegExp(`\\b${e}\\b`, 'i');
            return pattern.test(linkName);
        }) || '';
        const conditions = specialtyNServiceTypeData.specialtyCondition[specialty as SPECIALTY_CONDITION_KEYS];

        const regex = /\[([^[\]]*)\](?!.*\[)/g;
        const matches = linkName.match(regex);

        if (matches && matches.length > 0) {
            const state = matches[matches.length - 1].slice(1, -1);
            info.state = state;
        }
        info.conditions = conditions;
        info.specialty = specialty;
        info.serviceType = serviceType;
    }

    return info;
};
